import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=IconButton icons=Brand wrap=flex",
        "componentName": "IconButton",
        "icons": "Brand",
        "wrap": "flex"
      }}>{`<IconButton appearance="primary" icon={Brand} />
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="IconButton" mdxType="Props" />
    <h2 {...{
      "id": "appearance",
      "style": {
        "position": "relative"
      }
    }}>{`Appearance`}</h2>
    <p><inlineCode parentName="p">{`default: default`}</inlineCode></p>
    <p>{`Just like a regular Button, an IconButton has several variations for its
`}<inlineCode parentName="p">{`appearance`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=IconButton icons=Brand wrap=flex",
        "componentName": "IconButton",
        "icons": "Brand",
        "wrap": "flex"
      }}>{`<IconButton appearance="default" icon={Brand} />
<IconButton appearance="primary" icon={Brand} />
<IconButton appearance="secondary" icon={Brand} />
<IconButton appearance="plain" icon={Brand} />
<IconButton appearance="outline" icon={Brand} />
`}</code></pre>
    <h2 {...{
      "id": "icon",
      "style": {
        "position": "relative"
      }
    }}>{`Icon`}</h2>
    <p><inlineCode parentName="p">{`default: Placeholder`}</inlineCode></p>
    <p>{`Changing the contained Icon can be done by altering the `}<inlineCode parentName="p">{`icon`}</inlineCode>{` prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=IconButton icons=Brand,Checkmark,TagFill,Add wrap=flex",
        "componentName": "IconButton",
        "icons": "Brand,Checkmark,TagFill,Add",
        "wrap": "flex"
      }}>{`<IconButton appearance="primary" icon={Brand} />
<IconButton appearance="primary" icon={Checkmark} />
<IconButton appearance="primary" icon={TagFill} />
<IconButton appearance="primary" icon={Add} />
`}</code></pre>
    <h2 {...{
      "id": "circular",
      "style": {
        "position": "relative"
      }
    }}>{`Circular`}</h2>
    <p><inlineCode parentName="p">{`default: false`}</inlineCode></p>
    <p>{`An IconButton is either contained in a square or in a circle.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=IconButton icons=Brand wrap=flex",
        "componentName": "IconButton",
        "icons": "Brand",
        "wrap": "flex"
      }}>{`<IconButton circular={false} appearance="primary" icon={Brand} />
<IconButton circular={true} appearance="primary" icon={Brand} />
`}</code></pre>
    <h2 {...{
      "id": "size",
      "style": {
        "position": "relative"
      }
    }}>{`Size`}</h2>
    <p><inlineCode parentName="p">{`default: large`}</inlineCode></p>
    <p>{`IconButtons come in two sizes, large and small.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=IconButton icons=Brand wrap=flex",
        "componentName": "IconButton",
        "icons": "Brand",
        "wrap": "flex"
      }}>{`<IconButton size="small" appearance="primary" icon={Brand} />
<IconButton size="large" appearance="primary" icon={Brand} />
`}</code></pre>
    <h2 {...{
      "id": "disabled",
      "style": {
        "position": "relative"
      }
    }}>{`Disabled`}</h2>
    <p><inlineCode parentName="p">{`default: false`}</inlineCode></p>
    <p>{`To indicate to a user that an IconButton can not be interacted with, add the
`}<inlineCode parentName="p">{`disabled`}</inlineCode>{` prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=IconButton icons=Brand wrap=flex",
        "componentName": "IconButton",
        "icons": "Brand",
        "wrap": "flex"
      }}>{`<IconButton disabled={false} appearance="primary" icon={Brand} />
<IconButton disabled={true} appearance="primary" icon={Brand} />
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      